import Vue from 'vue'
import Vuex from 'vuex'

import UserStore from '@/store/user-store'

Vue.use(Vuex)

export let store

export function createStore () {
  store = new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',
    state: {},
    actions: {},
    mutations: {},
    getters: {},
    modules: {
      user: {
        namespaced: true,
        ...UserStore
      }
    }
  })
  return store
}
