import Vue from 'vue'
import App from '@/App.vue'
import Filters from '@/app/filters'

import { createRouter } from '@/router/index'
import { sync } from 'vuex-router-sync'
import store from '@/app/store'
import User from '@/app/user'

Object.keys(Filters).forEach(key => {
  Vue.filter(key, Filters[key])
})

Vue.config.productionTip = false

// create store and router instances
const router = createRouter()

router.afterEach((to, from) => {
  (function (m, e, t, r, i, k, a) {
    m[i] = m[i] || function () {
      (m[i].a = m[i].a || []).push(arguments)
    }
    m[i].l = 1 * new Date()
    k = e.createElement(t)
    a = e.getElementsByTagName(t)[0]
    k.async = 1
    k.src = r
    a.parentNode.insertBefore(k, a)
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')
  window.ym(88408088, 'init', { clickmap: true, trackLinks: true, accurateTrackBounce: true, trackHash: true })
})

// sync the router with the vuex store.
// this registers `store.state.route`
sync(store, router)

// основное приложение
const vueApp = new Vue({
  router,
  store,
  render: h => h(App)
})

User.checkSid().catch(() => User.autologin()).catch(() => {}).finally(function () {
  vueApp.$mount('#app')
})
