import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// Function to create routes
export function createRouter () {
  const adminRoutes = [
    // Админка
    {
      path: '/admin/api',
      component: () => import(/* webpackChunkName: "admin" */ '@/pages-admin/ApiView'),
      meta: { admin: true },
      name: 'admin-api'
    },
    // Этот путь должен стоять в самом низу
    {
      path: '/admin',
      component: () => import(/* webpackChunkName: "admin" */ '@/pages-admin/AdminView'),
      meta: { admin: true },
      name: 'admin'
    }
  ]

  return new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      ...adminRoutes,
      { path: '/', name: 'home', component: () => import(/* webpackChunkName: "home" */ '@/pages/HomeView') },
      {
        path: '/cards/:hash([^/]+)?',
        name: 'cards',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/CardsView')
      }
    ]
  })
}
