import qs from 'qs'
import axios from 'axios'
import config from '@/app/config'
import dom from '@/app/dom'
import User from '@/app/user'

async function call (
  method,
  params,
  type = 'post',
  formData = null
) {
  if (!params) {
    params = {}
  }

  return new Promise(function (resolve, reject) {
    if (!method) {
      reject(new Error('Method is undefined'))
    } else {
      let data

      const isDeleteSid = params && params.isGuestCall

      if (!isDeleteSid) {
        const sid = User.sid()
        if (sid) {
          params.sid = sid
        }
      } else {
        if (params.sid) {
          delete params.sid
        }
        delete params.isGuestCall
      }

      if (formData && formData instanceof FormData) {
        data = formData
        data.append('params', JSON.stringify(params))
      } else {
        data = qs.stringify({ params: JSON.stringify(params) })
      }

      return axios({
        method: type,
        url: config.urlEndpoint('apiMethodCall') + '/' + method,
        data
      }).then(response => {
        if (response.data && (typeof response.data.status !== 'undefined') && !response.data.status) {
          processErrors(response)
          reject(response.data)
        } else {
          resolve(response.data)
        }
      },
      errorResponse => {
        processErrors(errorResponse.response)
        return reject(errorResponse.response)
      }
      )
    }
  })
}

async function batch (data) {
  return new Promise(function (resolve, reject) {
    const props = Object.keys(data)
    const postData = { batch: [] }

    for (const prop of props) {
      let { method, params } = data[prop]
      if (!method) {
        reject(new Error('Не задано имя метода в batch'))
      }
      if (prop === 'status') {
        reject(
          new Error(
            'Нельзя использовать имя status в качестве возвращаемого значения в batch'
          )
        )
      }
      if (!params) {
        params = {}
      }
      postData.batch.push({ method, params, prop })
    }
    return axios
      .post(config.urlEndpoint('apiBatchCall'), qs.stringify(postData))
      .then(
        response => resolve(response.data),
        errorResponse => {
          processErrors(errorResponse.response)
          return reject(errorResponse.response)
        })
  }
  )
}

function showMessage (message, title = '', type = 'info', timeout = 5000) {
  try {
    if (typeof window !== 'undefined' && window.GrowlNotification) {
      __showMessage(message, title, type, timeout)
    } else {
      dom.loadScript(config.scripts.jgrowl, function () {
        __showMessage(message, title, type, timeout)
      })
    }
  } catch (e) {
    console.error(e)
  }
}

function __showMessage (message, title, type, timeout = 5000) {
  if (message) {
    if (!type) {
      type = 'alert' // success, error, warning, info
    }
    console.log({ message, title, type, timeout })
    window.GrowlNotification.notify({
      title,
      description: message,
      type,
      position: 'top-right',
      closeTimeout: timeout
    })
  }
}

/**
 * Обработать ответ с ошибкой
 * Поместить ошибку в store
 * @param response
 */
function processErrors (response) {
  if (response.data && (response.data.errors || response.data.message || response.data.error)) {
    let errors
    if (response.data.errors) {
      errors = response.data.errors
    } else if (response.data.error && response.data.error.description) {
      errors = [{ message: response.data.error.description }]
    } else {
      errors = response.data.errors || [{ message: response.data.message, code: response.data.code }]
    }
    for (const err of errors) {
      showMessage(err.message, '', 'error')
    }
  }
}

export default {
  call,
  batch,
  processErrors,
  showMessage
}
