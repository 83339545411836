function loadScript (src, cb = null) {
  if (!document.querySelector('script[src="' + src + '"]')) {
    let script = window.document.createElement('script')
    if (cb) {
      script.onload = cb
    }
    script.defer = true
    script.src = src
    window.document.head.appendChild(script)
  } else if (cb) {
    cb()
  }
}

export default {
  loadScript
}
