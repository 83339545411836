let scripts = {
  jgrowl: '/js/growl-notification.min.js'
}

export default {
  site: {
    title: 'Добрые открытки'
  },
  url: {
    frontend: process.env.VUE_APP_FRONTEND,
    backend: process.env.VUE_APP_BACKEND
  },
  domain: function () {
    return this.url.frontend.replace('http://', '').replace('https://', '').replace(':8080', '')
  },
  endpoint: {
    apiMethods: 'api/methods',
    apiGate: 'api/gate',
    apiMethodPrepare: 'api/method_prepare',
    apiMethodCall: 'api/method_call',
    apiBatchCall: 'api/batch_call'
  },
  urlEndpoint: function (endpoint) {
    'use strict'
    return this.url.backend + '/' + this.endpoint[endpoint]
  },
  scripts,
  addresses: {
    limit: 1
  },
  user: {
    cookies: {
      sid: 'sid',
      autologin: 'me'
    }
  }
}
