import Cookie from 'js-cookie'
import config from '@/app/config'

/**
 * Установить куку
 * @param string name Имя
 * @param string value Значение
 * @param int expires Протухание, дней
 * @returns string
 */
function set (name, value, expires = 0) {
  if (typeof value !== 'undefined') {
    if (!value) {
      Cookie.remove(name)
    } else {
      const domain = config.domain()
      const params = {
        httpOnly: false,
        domain: domain !== 'localhost' ? '.' + domain : domain
      }
      if (expires) {
        params.expires = expires
      }
      Cookie.set(name, value, params)
    }
  }
  return get(name)
}

function get (name) {
  return Cookie.get(name)
}

function remove (name, attributes) {
  return Cookie.remove(name, attributes)
}

export default {
  set,
  get,
  remove
}
