<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>

import config from './app/config'
import '@/assets/app.styl'

export default {
  name: 'App',
  data: () => ({
    //
  }),
  mounted () {
    document.title = config.site.title
  }
}
</script>
