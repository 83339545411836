import config from '@/app/config'
import Cookie from '@/app/cookie'
import store from '@/app/store'
import api from '@/app/api'

function processLoginResponse (response) {
  if (response.user) {
    store.commit('user/SET_MY_USER', response.user)
  }
  if (response.sid) {
    user.sid(response.sid)
  }
  return response.user
}

const user = {
  STATUS_DISABLED: 0,
  STATUS_ACTIVE: 1,

  USERGROUP_USER: 0,
  USERGROUP_ADMIN: 1,

  isGuest: () => store.getters['user/isGuest'],
  isAdmin: () => store.getters['user/isAdmin'],
  get: () => store.state.user.user ? store.state.user.user : {},

  sid (value) {
    if (typeof value === 'undefined') {
      return Cookie.get(config.user.cookies.sid)
    }
    return Cookie.set(config.user.cookies.sid, value)
  },
  autologin () {
    const iam = this.iam()
    if (iam) {
      const params = {
        iam
      }
      return api
        .call('user.login.hash', params)
        .then(processLoginResponse)
        .catch(function () {
          Cookie.set(config.user.cookies.autologin, null)
        })
    }
    return Promise.reject(new Error('Empty autologin'))
  },
  processLoginResponse,
  updateInfo () {
    console.log('updateInfo')
    return api.call('user.info').then(function (responseAxios) {
      const response = responseAxios.data
      if (response.status) {
        store.commit('user/SET_MY_USER', response.user)
      }
    })
  },
  signinSocial (domain, params) {
    return api
      .call('user.login.' + domain, params)
      .then(function (responseAxios) {
        return processLoginResponse(responseAxios)
      })
  },
  logout () {
    return api.call('user.logout').finally(function () {
      Cookie.set(config.user.cookies.sid, null)
      Cookie.set(config.user.cookies.autologin, null)
      store.commit('user/SET_MY_USER', null)
    })
  },
  iam (value) {
    if (typeof value === 'undefined') {
      return Cookie.get(config.user.cookies.autologin)
    }
    return Cookie.set(config.user.cookies.autologin, value, 365)
  },
  checkSid () {
    const sid = this.sid()
    return new Promise(function (resolve, reject) {
      if (!sid) {
        reject(new Error('Sid is empty'))
      } else {
        return api
          .call('user.info')
          .then(function (responseAxios) {
            if (responseAxios.user) {
              processLoginResponse(responseAxios)
              resolve(responseAxios.user)
            } else {
              reject(new Error('Api user.info status is zero'))
            }
          })
          .catch(function (e) {
            Cookie.set(config.user.cookies.sid, null)
            reject(e)
          })
      }
    })
  }
}

export default user
