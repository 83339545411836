import Vue from 'vue'
import User from '@/app/user'

export default {
  state: () => {
    return {
      user: null
    }
  },
  getters: {
    isGuest (state) {
      return (
        !state.user || [
          User.STATUS_ACTIVE
        ].indexOf(state.user.status) < 0
      )
    },
    isAdmin (state) {
      return (
        !!state.user &&
        state.user.usergroup === User.USERGROUP_ADMIN
      )
    }
  },
  actions: {},
  mutations: {
    SET_MY_USER (state, user) {
      Vue.set(state, 'user', user)
    },
    SET_MY_USER_DATA (state, { key, value }) {
      Vue.set(state.user, key, value)
    }
  }
}
