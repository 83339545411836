function fixSafariDate (dt) {
  return typeof dt === 'string' ? dt.replace(/\s/, 'T') : dt
}

function formatDate (value) {
  if (!value) {
    return ''
  }
  const dt = new Date(fixSafariDate(value))
  return dt.toLocaleString('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  })
}

function getDate (value) {
  if (value instanceof Date) {
    return value
  }
  return new Date(fixSafariDate(value))
}

function dateYmd (value) {
  if (!value) {
    return ''
  }
  const dt = getDate(value)
  return dt.toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

function dateDmy (value) {
  if (!value) {
    return ''
  }
  const dt = getDate(value)
  return dt.toLocaleString('ru', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })
}

function dateDmyShort (value) {
  if (!value) {
    return ''
  }
  const dt = getDate(value)
  return dt.toLocaleString('ru', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  })
}

function dateISO (value) {
  if (!value) return ''
  const dt = getDate(value)
  return [
    dt.getFullYear(),
    ((dt.getMonth() + 1) + '').padStart(2, '0'),
    (dt.getDate() + '').padStart(2, '0')
  ].join('-') + 'T' + [
    (dt.getHours() + '').padStart(2, '0'),
    (dt.getMinutes() + '').padStart(2, '0')
  ].join(':')
}

function dateYmdHis (value) {
  if (!value) return ''
  const dt = getDate(value)
  return [
    dt.getFullYear(),
    ((dt.getMonth() + 1) + '').padStart(2, '0'),
    (dt.getDate() + '').padStart(2, '0')
  ].join('-') + ' ' + [
    (dt.getHours() + '').padStart(2, '0'),
    (dt.getMinutes() + '').padStart(2, '0'),
    (dt.getSeconds() + '').padStart(2, '0')
  ].join(':')
}

function pluralForm (n, w1, w2 = '', w3 = '') {
  let ret = ''
  if (n < 0) {
    n *= -1
  }

  if (n >= 11 && n <= 19) {
    ret = (w3 !== '' ? w3 : w1)
  } else {
    n %= 10
    if (n === 1) {
      ret = w1
    } else if (n === 0 || n >= 5) {
      ret = (w3 !== '' ? w3 : w1)
    } else {
      ret = (w2 !== '' ? w2 : w1)
    }
  }
  return ret
}

function dateDiff (dt1, dt2, addSeconds1 = 0, addSeconds2 = 0, useSpecials = true) {
  const TS_MINUTE = 60
  const TS_HOUR = TS_MINUTE * 60
  const TS_DAY = TS_HOUR * 24
  const TS_WEEK = TS_DAY * 7
  const TS_MONTH = TS_DAY * 30
  const TS_YEAR = TS_DAY * 365

  const nowd = dt1.getTime() + addSeconds1 * 1000
  const pastd = dt2.getTime() + addSeconds2 * 1000
  const diff = Math.floor((nowd - pastd) / 1000)

  let ret = ''

  if (diff > 0) {
    let special = false
    let word = ''
    let n
    if (diff < TS_MINUTE) {
      n = (diff > 0 ? diff : 1)
      if (useSpecials && (n >= 25 && n <= 35)) {
        word = 'полминуты'
        special = true
      } else {
        word = pluralForm(n, 'секунду', 'секунды', 'секунд')
      }
    } else if (diff < TS_HOUR) {
      n = parseInt(diff / TS_MINUTE)
      if (useSpecials && (n >= 25 && n <= 35)) {
        word = 'полчаса'
        special = true
      } else {
        word = pluralForm(n, 'минуту', 'минуты', 'минут')
      }
    } else if (diff < TS_DAY) {
      n = parseInt(diff / TS_HOUR)
      word = pluralForm(n, 'час', 'часа', 'часов')
    } else if (diff < TS_WEEK) {
      n = parseInt(diff / TS_DAY)
      word = pluralForm(n, 'день', 'дня', 'дней')
    } else if (diff < TS_MONTH) {
      n = parseInt(diff / TS_WEEK)
      word = pluralForm(n, 'неделю', 'недели', 'недель')
    } else if (diff < TS_YEAR) {
      n = parseInt(diff / TS_MONTH)
      word = pluralForm(n, 'месяц', 'месяца', 'месяцев')
    } else {
      n = parseInt(diff / TS_YEAR)
      word = pluralForm(n, 'год', 'года', 'лет')
    }

    if (special) {
      ret = word
    } else if (n === 1) {
      ret = word
    } else {
      ret = n + ' ' + word
    }
  }

  return ret
}

function days (firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((firstDate - secondDate) / oneDay))
}

function dateAgo (dt, addSeconds = 0) {
  return dateDiff(new Date(), new Date(fixSafariDate(dt)), 0, addSeconds)
}

function dateTill (dt, addSeconds = 0) {
  return dateDiff(new Date(fixSafariDate(dt)), new Date(), addSeconds)
}

function zerosToK (value, space = ' ') {
  let letter = ''
  if (value >= 1000000) {
    value = Math.round(value / 1000000)
    letter = space + 'M'
  } else if (value > 1000) {
    value = Math.round(value / 1000)
    letter = space + 'K'
  }
  return value + letter
}

function difficulty (value) {
  let difficulty = 'Не определена'
  switch (value) {
    case 'hard' :
      difficulty = 'Высокая'
      break
    case 'medium' :
      difficulty = 'Средняя'
      break
    case 'simple' :
      difficulty = 'Низкая'
      break
  }
  return difficulty
}

function ucfirst (str) {
  const f = str.charAt(0).toUpperCase()
  return f + str.substr(1, str.length - 1)
}

function nbsp (str) {
  return str.replace(/\s/g, '&nbsp;')
}

function svgIcon (path, size = '', cls = '') {
  let style = ''
  if (size === 'small') {
    style = 'width: 18px; height: 18px'
  } else if (size === 'smallest') {
    style = 'width: 12px; height: 12px'
  } else if (size === 'smaller') {
    style = 'width: 15px; height: 15px'
  }
  return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" role="img" aria-hidden="true" class="v-icon notranslate v-icon--svg ' + cls + '"' +
    (style ? ' style = "' + style + '"' : '') +
    '><path d="' + path + '"></path></svg>'
}

function domain (hostName) {
  let base = ''
  if (hostName && typeof hostName === 'string') {
    let firstSlashes = hostName.indexOf('//')
    if (firstSlashes >= 0) {
      base = hostName.substring(firstSlashes + 2)
    }
    let firstSlash = base.indexOf('/')
    if (firstSlash > 0) {
      base = base.substring(0, firstSlash)
    }
  }
  return base
}

function baseDomain (hostName) {
  let base = ''
  if (hostName && typeof hostName === 'string') {
    base = domain(hostName)
    base = base.substring(base.lastIndexOf('.', base.lastIndexOf('.') - 1) + 1)
  }
  return base
}

function maxLines (text, num = 3) {
  let lines = text.split('\n')
  return (lines.slice(0, num).join('\n') + ' ' + lines.slice(num).join(' ')).trim()
}

function cut (text, length = 100) {
  if (text.length <= length) {
    return text
  }
  return text.substring(0, length) + '...'
}

function shortString (s, length, reverse = false) {
  const stopChars = [' ', '/', '&']
  const acceptableShortness = length * 0.80 // When to start looking for stop characters
  if (reverse) {
    s = s.split('').reverse().join('')
  }

  let shortStr = ''
  for (let i = 0; i < shortStr - 1; i++) {
    shortStr += s[i]
    if (i >= acceptableShortness && stopChars.indexOf(s[i]) >= 0) {
      break
    }
  }
  if (reverse) {
    return shortStr.split('').reverse().join('')
  }
  return shortStr
}

function shortUrl (url, length = 50, delim = '..') {
  const chunkLength = length / 2
  url = url.replace('http://', '').replace('https://', '')

  if (url.length > length) {
    const startChunk = shortString(url, chunkLength, false)
    const endChunk = shortString(url, chunkLength, true)
    url = startChunk + delim + endChunk
  }
  return url
}

export default {
  ucfirst,
  formatDate,
  dateYmd,
  dateDmy,
  dateDmyShort,
  dateYmdHis,
  dateISO,
  pluralForm,
  dateAgo,
  dateTill,
  dateDiff,
  zerosToK,
  nbsp,
  difficulty,
  svgIcon,
  baseDomain,
  domain,
  maxLines,
  cut,
  days,
  shortUrl
}
